import photosManifest from '../photosdata/photosManifest.js';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Optional for blur effect

export default function CatPhotos({ handlePhotoClick }) {

    // handle individual image click
    const handleClick = (link, id, filename) => {
        link.preventDefault();
        // Set parent component prop that determines layout
        handlePhotoClick.setCurrDisp('single');
        // set index to that of individual image
        handlePhotoClick.setCurrSingleIndex(id);
        // set ref with photo filename to use to scroll when going back
        handlePhotoClick.scrollToPhoto.current = filename;
    }

    // current category index is used to get the right array of images
    const currCatIndex = handlePhotoClick.currCatIndex;



    // iterate the relevant index to output all the images
    const catPhotos = photosManifest[currCatIndex].map(photo =>
        // deconstruct vars
        // const { filename, id, size, alt, width, height } = photo;

        <figure id={photo.filename} key={photo.id} className={photo.size}>
            <a onClick={(link) => handleClick(link, photo.id, photo.filename)} href={`/images/${photo.folder}/${photo.filename}.jpg`}>
                <LazyLoadImage
                    src={`/images/${photo.folder}/${photo.filename}.jpg`}
                    alt={photo.alt}
                    effect="blur"

                />
            </a>

        </figure >

    )
    return catPhotos;

}

