export default function NavItems({ handleNavClick }) {

    const handleClick = (id, title) => {

        handleNavClick.setCurrCatIndex(id);
        handleNavClick.setCurrCatTitle(title);
        handleNavClick.setCurrDisp('cat');
        handleNavClick.scrollToPhoto.current = '';

    }

    // Nav
    const pages = [{
        id: 0,
        cat: 'Home'
    }, {
        id: 1,
        cat: 'Animals'
    }, {
        id: 2,
        cat: 'Architecture'
    }, {
        id: 3,
        cat: 'Birds'
    }, {
        id: 4,
        cat: 'Landscape'
    }, {
        id: 5,
        cat: 'Machines'
    }, {
        id: 6,
        cat: 'Nature'
    }];

    const navItems = pages.map(item =>
        <button key={item.id}
            aria-label={item.cat === 'Home' && item.cat}
            className={handleNavClick.currCatTitle === item.cat ? 'current' : ''}
            onClick={() => handleClick(item.id, item.cat)}>
            <span id={`nav-${item.id}`}>{item.cat}</span>
        </button>

    )
    return navItems;

}

