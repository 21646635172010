const folder = 'animals';
const animals = [
    {
        id: 0,
        folder,
        filename: "elephants",
        title: "Elephant",
        alt: "",
        caption: "San Diego Zoo, California",
        size: "half",
        width: 1024,
        height: 732
    },
    {
        id: 1,
        folder,
        filename: "icelandic-horses",
        title: "Icelandic Horses",
        alt: "",
        caption: "Hella, Iceland",
        size: "half",
        width: 1024,
        height: 732
    },
    {
        id: 2,
        folder,
        filename: "turtle",
        title: "Green Sea Turtle",
        alt: "",
        caption: "Punaluʻu Beach, Hawai’i",
        size: "full",
        width: 1024,
        height: 732
    },
    {
        id: 3,
        folder,
        filename: "squirrel",
        title: "Squirrel",
        alt: "",
        caption: "Harvey Mudd College, Claremont, California",
        size: "half",
        width: 1024,
        height: 732
    },
    {
        id: 4,
        folder,
        filename: "gorilla",
        title: "Gorilla",
        alt: "San Diego Wild Animal Park, California",
        caption: "",
        size: "half",
        width: 1024,
        height: 732
    },
    {
        id: 5,
        folder,
        filename: "horse",
        title: "Icelandic Horse",
        alt: "",
        caption: "Hella, Iceland",
        size: "full",
        width: 1024,
        height: 732
    },




]

export default animals;