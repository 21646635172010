import "./Portfolio.css";
import NavItems from "./components/NavItems";
import CatPhotos from "./components/CatPhotos";
import SinglePhoto from "./components/SinglePhoto";
//import { LazyLoadImage } from 'react-lazy-load-image-component';
//import 'react-lazy-load-image-component/src/effects/blur.css'; // Optional for blur effect

import { useState, useEffect, useRef } from "react";

function Portfolio() {
  // each category of images has an array of object literals with all the deets of the
  // individual images
  // Each of those arrays are values of another object literal

  // What we're currently displaying: category or single image
  const [currDisp, setCurrDisp] = useState("cat");
  // Current category title to show at top of page
  const [currCatTitle, setCurrCatTitle] = useState("Home");
  // Index of the current category's object in the data array 0 = Home images etc.
  const [currCatIndex, setCurrCatIndex] = useState(0);
  // Index in the of the single image being viewed
  const [currSingleIndex, setCurrSingleIndex] = useState("");
  // Ref to store the photo filename for use when returning to cat view and scrolling
  const scrollToPhoto = useRef("");

  const date = new Date();
  const year = date.getFullYear();

  // watch current display setting, which will be set when
  // back to button used.
  // Scroll to the image that was being viewed before buton pressed
  useEffect(() => {
    if (currDisp === "single") return; // if it's a single view, bail
    // get the element to scroll to and go for it. Smoothly
    // note: have to get the element 'fresh' as if the element itself
    // is save in a state somewhere else, scrolltoview won't work
    if (scrollToPhoto.current) {
      const originatingPhoto = document.getElementById(scrollToPhoto.current);
      // use a timeout to pause briefly otherwise LazyLoadPhoto breaks the scrolling
      if (originatingPhoto) {
        const timeoutId = setTimeout(() => {
          originatingPhoto.scrollIntoView({ behavior: "smooth" });
        }, 100);
        originatingPhoto.firstChild.focus();
        return () => clearTimeout(timeoutId);
      }
    }
  }, [currDisp]); // only call when changing display type

  return (
    <main id="site-wrapper" className="App">
      <header id="page-header" className="App-header">
        <h1>Peter Hobley Photography</h1>
        <nav>
          <NavItems
            handleNavClick={{
              setCurrCatIndex,
              setCurrCatTitle,
              setCurrDisp,
              currCatIndex,
              currCatTitle,
              setCurrSingleIndex,
              scrollToPhoto,
            }}
          />
        </nav>
      </header>
      <div id="page-content">
        {currCatTitle !== "Home" && currDisp === "cat" && (
          <h2>{currCatTitle}</h2>
        )}
        {currDisp === "cat" && (
          <div id="photos-wrapper">
            <CatPhotos
              handlePhotoClick={{
                setCurrDisp,
                setCurrSingleIndex,
                currCatIndex,
                scrollToPhoto,
              }}
            />
          </div>
        )}
        {currDisp === "single" && (
          <div id="photo-wrapper">
            <SinglePhoto
              setImage={{ currCatIndex, currSingleIndex }}
              handleBackButton={{ setCurrCatIndex, setCurrDisp, currCatTitle }}
            />
          </div>
        )}
      </div>
      <footer id="footer">
        <small>
          © Copyright <a href="https://www.hobley.uk/">Peter Hobley</a> 2009–
          {year} | <a href="mailto:hello@hobley.us">hello@hobley.us</a>
        </small>
      </footer>
    </main>
  );
}

export default Portfolio;
