const folder = 'nature';

const nature = [
    {
        id: 0,
        folder,
        filename: "tree",
        title: "Lone Tree",
        alt: "",
        caption: "New Forest, UK",
        size: "full",
        width: 1024,
        height: 732
    },
    {
        id: 1,
        folder,
        filename: "",
        title: "",
        alt: "",
        caption: "",
        size: "full",
        width: 1024,
        height: 732
    },
    {
        id: 2,
        folder,
        filename: "",
        title: "",
        alt: "",
        caption: "",
        size: "full",
        width: 1024,
        height: 732
    },
    {
        id: 3,
        folder,
        filename: "",
        title: "",
        alt: "",
        caption: "",
        size: "full",
        width: 1024,
        height: 732
    },
    {
        id: 4,
        folder,
        filename: "",
        title: "",
        alt: "",
        caption: "",
        size: "full",
        width: 1024,
        height: 732
    },
    {
        id: 5,
        folder,
        filename: "",
        title: "",
        alt: "",
        caption: "",
        size: "full",
        width: 1024,
        height: 732
    },
    {
        id: 6,
        folder,
        filename: "",
        title: "",
        alt: "",
        caption: "",
        size: "full",
        width: 1024,
        height: 732
    },
    {
        id: 7,
        folder,
        filename: "",
        title: "",
        alt: "",
        caption: "",
        size: "full",
        width: 1024,
        height: 732
    },
    {
        id: 8,
        folder,
        filename: "",
        title: "",
        alt: "",
        caption: "",
        size: "full",
        width: 1024,
        height: 732
    },
    {
        id: 9,
        folder,
        filename: "",
        title: "",
        alt: "",
        caption: "",
        size: "full",
        width: 1024,
        height: 732
    },
]

export default nature;