const folder = "birds";

const birds = [
  {
    id: 0,
    folder,
    filename: "spotted-towhee",
    title: "Spotted Towhee",
    alt: "Spotted Towhee",
    caption: "",
    size: "full",
  },

  {
    id: 1,
    folder,
    filename: "house-wren",
    title: "House Wren",
    alt: "House Wrem",
    caption: "",
    size: "half",
  },
  {
    id: 2,
    folder,
    filename: "western-bluebird",
    title: "Female Western Bluebird",
    alt: "Female Western Bluebird",
    caption: "",
    size: "half",
  },
  {
    id: 3,
    folder,
    filename: "puffin",
    title: "Atlantic Puffin",
    alt: "Puffin sitting on grass cliff.",
    caption: "Hermaness, Shetland",
    size: "full",
    width: 1024,
    height: 732,
  },
  {
    id: 4,
    folder,
    filename: "grackle",
    title: "Grackle",
    alt: "Grackle on edge of trash can",
    caption: "",
    size: "half",
  },
  {
    id: 5,
    folder,
    filename: "cormorant",
    title: "Cormorant",
    alt: "cormorant on ground with wing spread",
    caption: "",
    size: "half",
  },
  {
    id: 6,
    folder,
    filename: "snowy-egret-2",
    title: "Snowy Egret",
    alt: "Snowy Egret",
    caption: "",
    size: "half",
  },
  {
    id: 7,
    folder,
    filename: "demoiselle-crane",
    title: "Demoiselle Crane",
    alt: "Demoiselle Crane",
    caption: "San Diego Safari Park",
    size: "half",
  },
  {
    id: 8,
    folder,
    filename: "great-egret",
    title: "Great Egret",
    alt: "Great Egret",
    caption: "",
    size: "full",
  },
  {
    id: 9,
    folder,
    filename: "blue-heron",
    title: "Blue Heron",
    alt: "blue heron",
    caption: "",
    size: "half",
  },
  {
    id: 10,
    folder,
    filename: "osprey",
    title: "Osprey",
    alt: "Osprey, wings spread in sky",
    caption: "",
    size: "half",
  },
];

export default birds;
