const folder = 'landscape';

const landscape = [
    {
        id: 0,
        folder: "landscape",
        filename: "látrabjarg",
        title: "Látrabjarg",
        alt: "Dramatic sea cliff with lone figure",
        caption: "Westfjords, Iceland",
        size: "full",
        width: 2048,
        height: 1365
    },
    {
        id: 1,
        folder: "landscape",
        filename: "arnardrangur",
        title: "Arnardrangur",
        alt: "Lone sea stack on black sand, waves crashing",
        caption: "Dyrhólaey, Iceland",
        size: "half",
        width: 1024,
        height: 683
    },
    {
        id: 2,
        folder,
        filename: "crosses",
        title: "Ynys Llanddwyn",
        alt: "",
        caption: "Anglesey, UK",
        size: "half",
        width: 1024,
        height: 732
    },
    {
        id: 3,
        folder,
        filename: "rauðisandur",
        title: "Rauðisandur",
        alt: "",
        caption: "Iceland",
        size: "full",
        width: 1024,
        height: 732
    },
    {
        id: 4,
        folder,
        filename: "farmhouse",
        title: "Farmhouse",
        alt: "",
        caption: "Westfjords, Iceland",
        size: "half",
        width: 1024,
        height: 732
    },

    {
        id: 5,
        folder,
        filename: "on-the-road",
        title: "On the Road, Westfjords",
        alt: "",
        caption: "",
        size: "half",
        width: 1024,
        height: 732
    },
    {
        id: 6,
        folder,
        filename: "isafjordur",
        title: "Ísafjörður",
        alt: "",
        caption: "Westfjords, Iceland",
        size: "full",
        width: 1024,
        height: 732
    },
    {
        id: 7,
        folder,
        filename: "snow-day",
        title: "Snow Day",
        alt: "",
        caption: "Plains of Abraham, Quebec City",
        size: "half",
        width: 1024,
        height: 732
    },
    {
        id: 8,
        folder,
        filename: "skogafoss",
        title: "Skógafoss",
        alt: "",
        caption: "Iceland",
        size: "half",
        width: 1024,
        height: 732
    }
]

export default landscape;