import home from './home.js';
import birds from './birds.js';
import animals from './animals.js';
import architecture from './architecture.js';
import landscape from './landscape.js';
import machines from './machines.js';
import nature from './nature.js';

const photosManifest = [
    home,
    animals,
    architecture,
    birds,
    landscape,
    machines,
    nature,
]

export default photosManifest;