const folder = "architecture";

const architecture = [
  {
    id: 0,
    folder,
    filename: "menai-bridge",
    title: "Menai Bridge",
    alt: "",
    caption: "Menai Bridge, Gwynedd, Wales, UK",
    size: "full",
    width: 1024,
    height: 732,
  },
  {
    id: 1,
    folder,
    filename: "30-rock",
    title: "30 Rock",
    alt: "Looking up at art deco skyscraper",
    caption: "New York, NY",
    size: "half",
    width: 1707,
    height: 2560,
  },
  {
    id: 2,
    folder,
    filename: "business-district",
    title: "Business District",
    alt: "",
    caption: "San Francisco, CA",
    size: "half",
    width: 1024,
    height: 732,
  },
  {
    id: 3,
    folder,
    filename: "downtown-la",
    title: "Downtown Los Angeles",
    alt: "",
    caption: "",
    size: "full",
    width: 1024,
    height: 732,
  },

  {
    id: 4,
    folder,
    filename: "one-wtc",
    title: "One World Trade Center",
    alt: "",
    caption: "",
    size: "half",
    width: 1024,
    height: 732,
  },
  {
    id: 5,
    folder,
    filename: "realty-ritz",
    title: "Realty and Ritz",
    alt: "",
    caption: "NoMad District, NYC",
    size: "half",
    width: 1024,
    height: 732,
  },
  {
    id: 6,
    folder,
    filename: "pilot-cottage",
    title: "Pilot Cottage",
    alt: "",
    caption:
      "One of a row of four cottages built before 1830 for the lighthouse keepers, pilots and lifeboat men on Ynys Llanddwyn (Lovers’ Island), Anglesey, Wales, UK.",
    size: "full",
    width: 1024,
    height: 732,
  },
  {
    id: 7,
    folder,
    filename: "basilica-sainte-anne-de-beaupre-1",
    title: "Basilica of Sainte-Anne-de-Beaupre",
    alt: "",
    caption: "Quebec",
    size: "half",
    width: 1024,
    height: 732,
  },
  {
    id: 8,
    folder,
    filename: "basilica-sainte-anne-de-beaupre",
    title: "Basilica of Sainte-Anne-de-Beaupre",
    alt: "",
    caption: "Quebec",
    size: "half",
    width: 732,
    height: 1024,
  },
  {
    id: 9,
    folder,
    filename: "justice",
    title: "Justice",
    alt: "",
    caption: "U.S. District Courthouse, Southern District of New York",
    size: "half",
    width: 1024,
    height: 732,
  },
];

export default architecture;
