import birds from './birds';
import architecture from './architecture';
import machines from './machines';
import landscape from './landscape';
import animals from './animals';

const damioselle = { ...birds[7], id: 0, size: "full" }
const thirtyRock = { ...architecture[1], id: 1 }
const gardur = machines[2];
const latrabjarg = { ...landscape[0], id: 3 };
const basilica = { ...architecture[8], id: 4 };
const justice = { ...architecture[9], id: 5 };
const westernBluebird = { ...birds[2], id: 6, size: "full" }
const arnardrangur = { ...landscape[1], id: 7 };
const horses = { ...animals[1], id: 8 };
const spitfire = { ...machines[3], id: 9, size: "full" };

const home = [
    damioselle,
    thirtyRock,
    gardur,
    latrabjarg,
    basilica,
    justice,
    westernBluebird,
    arnardrangur,
    horses,
    spitfire,
]

export default home;