import photosManifest from '../photosdata/photosManifest.js';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Optional for blur effect

export default function SinglePhoto({ handleBackButton, setImage }) {

    // When button clicked, set display to cat view
    // (and the parent comp will make the magic happen)
    const handleClick = () => {
        handleBackButton.setCurrDisp('cat');
    }

    // deconstruct variables
    // Title to use in back button
    const { currCatTitle } = handleBackButton;
    // get the single image details from relevant array
    const { folder, filename, title, caption, alt } = photosManifest[setImage.currCatIndex][setImage.currSingleIndex];

    return (
        <>
            <button onClick={() => handleClick(filename)}>&laquo; Back to {currCatTitle}</button>
            <figure>
                <LazyLoadImage alt={alt} width="100%" src={`/images/${folder}/${filename}.jpg`} />
                <figcaption><span>{title}</span><span>{caption}</span></figcaption>
            </figure>
        </>
    )
}

