const folder = 'machines';

const machines = [
    {
        id: 0,
        folder,
        filename: "mustangs",
        title: "Mustangs",
        alt: "Row of Mustang aircraft",
        caption: "Chino Airport, California",
        size: "full",
        width: 2048,
        height: 1371
    },
    {
        id: 1,
        folder,
        filename: "sabre",
        title: "F-86 Sabre",
        alt: "Close up of gront of F-86 Sabre",
        caption: "Chino Airport, California",
        size: "half",
        width: 1714,
        height: 2560
    },
    {
        id: 2,
        folder,
        filename: "garðar",
        title: "Garðar BA 64",
        alt: "Front view of beached rusting fishing boat",
        caption: "Sauðlauksdalur, Iceland",
        size: "half",
        width: 1770,
        height: 2560
    },
    {
        id: 3,
        folder,
        filename: "spitfire",
        title: "Spitfire MkXIV",
        alt: "Close up from behind of spitfire at dawn",
        caption: "Chino Airport, California",
        size: "full",
        width: 2048,
        height: 1371
    },
    {
        id: 4,
        folder,
        filename: "finger-four",
        title: "Missing Man Formation",
        alt: "",
        caption: "P-51D, P-38, P-47 and F-22 prepare to perform a “missing man” salute at Planes of Fame Airshow, Chino, California",
        size: "half",
        width: 1024,
        height: 686
    },
    {
        id: 5,
        folder,
        filename: "steam",
        title: "Steam Engine",
        alt: "",
        caption: "Manchester Museum of Science and Industry, UK",
        size: "half",
        width: 1024,
        height: 685
    },
    {
        id: 6,
        folder,
        filename: "fw190-b25",
        title: "Fw-190 and B-25 Mitchell",
        alt: "",
        caption: "Planes of Fame Airshow, Chino, California",
        size: "full",
        width: 1024,
        height: 683
    }

]

export default machines;